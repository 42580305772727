.status-point {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #fff;
    border: 2px solid #fff;
    z-index: 1;
    @each $color-name, $color-value in $colors {
        &.point-#{$color-name} {
            background-color: $color-value;
            border-color: $color-value;
        }
    }
}