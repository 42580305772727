.p-0 {
    padding: 0;
}

.pt-0 {
    padding-top: 0;
}

.pr-0 {
    padding-right: 0;
}

.pb-0 {
    padding-bottom: 0;
}

.pl-0 {
    padding-left: 0;
}

.px-0 {
    padding-left: 0;
    padding-right: 0;
}

.py-0 {
    padding-top: 0;
    padding-bottom: 0;
}

.p-1 {
    padding: 0.25rem;
}

.pt-1 {
    padding-top: 0.25rem;
}

.pr-1 {
    padding-right: 0.25rem;
}

.pb-1 {
    padding-bottom: 0.25rem;
}

.pl-1 {
    padding-left: 0.25rem;
}

.px-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
}

.py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}

.p-2 {
    padding: 0.5rem;
}

.pt-2 {
    padding-top: 0.5rem;
}

.pr-2 {
    padding-right: 0.5rem;
}

.pb-2 {
    padding-bottom: 0.5rem;
}

.pl-2 {
    padding-left: 0.5rem;
}

.px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.p-3 {
    padding: 1rem;
}

.pt-3 {
    padding-top: 1rem;
}

.pr-3 {
    padding-right: 1rem;
}

.pb-3 {
    padding-bottom: 1rem;
}

.pl-3 {
    padding-left: 1rem;
}

.px-3 {
    padding-left: 1rem;
    padding-right: 1rem;
}

.py-3 {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.p-4 {
    padding: 1.5rem;
}

.pt-4 {
    padding-top: 1.5rem;
}

.pr-4 {
    padding-right: 1.5rem;
}

.pb-4 {
    padding-bottom: 1.5rem;
}

.pl-4 {
    padding-left: 1.5rem;
}

.px-4 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.py-4 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}

.p-5 {
    padding: 3rem;
}

.pt-5 {
    padding-top: 3rem;
}

.pr-5 {
    padding-right: 3rem;
}

.pb-5 {
    padding-bottom: 3rem;
}

.pl-5 {
    padding-left: 3rem;
}

.px-5 {
    padding-left: 3rem;
    padding-right: 3rem;
}

.py-5 {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.p-auto {
    padding: auto;
}

.pt-auto {
    padding-top: auto;
}

.pr-auto {
    padding-right: auto;
}

.pb-auto {
    padding-bottom: auto;
}

.pl-auto {
    padding-left: auto;
}

.px-auto {
    padding-left: auto;
    padding-right: auto;
}

.py-auto {
    padding-top: auto;
    padding-bottom: auto;
}