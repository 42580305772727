.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9995;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
}

.modal-content {
  background-color: white;
  border-radius: 5px;
  padding: 1rem;
  min-width: 33%;
  max-width: 67%;
  max-height: 90%;
  box-shadow: $base-elevation;
}

.modal-footer {
  margin-top: 1rem;
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
  align-items: center;
}

#modal-dish {
  .modal-content {
    max-width: 50%;
    max-height: 80%;
  }
}

#modal-beta {
  &.modal {
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
  }
  .modal-content {
    width: 75vw;
    height: 80vh;
    max-width: 75vw;
    max-height: 80vh;
    border-radius: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    .beta-content {
      max-width: 60%;
      min-width: 60%;
      margin: 3rem 0 3rem 0;

      h2 {
        margin-top: 0;
      }
    }

    input {
      border: 1px solid gray;
    }

    h2 {
      margin-bottom: 0;
    }
  }
}

@media (max-width: breakpoint("laptop")) {
  #modal-dish {
    .modal-content {
      max-width: 85%;
      max-height: 85%;
    }
  }

  #modal-two-fa {
    .modal-content {
      max-width: 85%;
      max-height: 85%;
    }

    .modal-body {
      flex-direction: column;

      & > div {
        width: 100%;
      }
    }
  }
}

@media (max-width: breakpoint("tablet")) {
  #modal-beta {
    .modal-content {
      width: 90vw;
      height: 90vh;
      max-width: 90vw;
      max-height: 90vh;

      .beta-content {
        max-width: 75%;
        min-width: 75%;
      }
    }
  }

  #modal-dish {
    .modal-content {
      max-width: 100%;
      max-height: 100%;
    }
  }

  #modal-two-fa {
    .modal-content {
      max-width: 100%;
      max-height: 100%;
    }

    .modal-body {
      flex-direction: column;

      & > div {
        width: 100%;
      }
    }
  }

  #modal-beta {
    .beta-content {
      max-width: 100% !important;
      max-height: 100% !important;
    }

    .modal-beta-input-grp {
      flex-direction: column;
    }
  }
}

@media (max-width: breakpoint("phone")) {
  #modal-beta {
    .modal-content {
      width: 95vw;
      height: 95vh;
      max-width: 95vw;
      max-height: 95vh;

      .beta-content {
        max-width: 75%;
      }
    }
  }

  #modal-dish {
    .modal-content {
      max-width: 100%;
      max-height: 100%;
    }
  }

  #modal-two-fa {
    .modal-content {
      max-width: 100%;
      max-height: 100%;
    }

    .modal-body {
      flex-direction: column;

      & > div {
        width: 100%;
      }
    }
  }
}
