@each $color-name, $color-value in $colors {
  .notification-#{$color-name} {
    &.unread-notification {
      background-color: palette("pastel-" + $color-name);
    }
  }
}

.notification-primary {
  &.read-notification {
    background-color: palette("pastel-medium");
  }
}

.read-notification {
  background-color: $grey-1;
}

#notifications {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 1000;
  width: 500px;
  background: white;
  box-shadow: $base-elevation;
  border-radius: 4px;
  padding: 0.5rem;

  p {
    margin: 0;
  }
}

.notifications-header {
  padding: 0.5rem;
}

.notifications-container {
  max-height: 500px;
  overflow-y: auto;
}

.notification-item {
  padding: 0.6rem 1rem;
  border-radius: 10px;
  margin-bottom: 0.5rem;
}

@media (max-width: breakpoint("laptop")) {
  #notifications {
    top: 85px;
    width: 100%;
  }
}

@media (max-width: breakpoint("tablet")) {
  #notifications {
    top: 85px;
    width: 100%;
  }
}

@media (max-width: breakpoint("phone")) {
  #notifications {
    top: 85px;
    width: 100%;
  }
}
