#sidebar {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: $bg-padding-y;
  bottom: $bg-padding-y;
  padding: 4rem 2.5rem 2.5rem 2.5rem;
  box-sizing: border-box;
  background-color: palette("pastel-light");
  height: calc(100vh - #{2 * $bg-padding-y});
  width: $sidebar-width;
  border-radius: 15px !important;

  .sidebar-header {
    width: fit-content;
    cursor: pointer;
    margin-bottom: 2rem;
  }

  .sidebar-text-span {
    margin-bottom: 5rem;
  }

  .sidebar-list {
    overflow-y: auto;
  }

  .sidebar-item {
    width: 100%;
    margin-top: 0.2rem;

    .sidebar-link {
      display: block;
      text-decoration: none !important;
      padding: 20px;
      width: 100%;
      border-radius: 50px;
    }

    &:hover .sidebar-link {
      background-color: lighten(palette("pastel-danger"), 2);
    }

    &.active .sidebar-link {
      background-color: palette("pastel-danger");
      box-shadow: $sidebar-elevation;
    }

    &.active {
      font-weight: bold;
    }
  }

  .sidebar-burger {
    display: none;
  }
}

.sidebar-page-wrapper {
  padding: $bg-padding-y $bg-padding-x;
  display: flex;

  .content {
    padding-left: calc(#{$sidebar-width} + #{$bg-padding-x} + #{4.5rem});
  }
}

@media (max-width: breakpoint("small-screen")) {
  #sidebar {
    width: calc($sidebar-width - 5%);
  }
}

@media (max-width: breakpoint("laptop")) {
  #sidebar {
    position: relative;
    height: fit-content;
    width: 100%;
    padding: 10px;
    border-radius: 0;

    .sidebar-header {
      margin-bottom: 1.2rem;
    }

    .sidebar-text-span {
      margin-bottom: 0;
    }

    .sidebar-list {
      display: none;
    }
  }

  .sidebar-page-wrapper {
    flex-direction: column;
    height: fit-content;
  }
  .sidebar-page-wrapper .content {
    padding-left: 0;
  }

  .register-step {
    width: 100%;
  }

  .sidebar-page-wrapper {
    padding: 8px 12px;
    height: 100vh !important;
  }

  #sidebar {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    top: 0;

    .sidebar-header {
      margin: 0 !important;
    }

    .sidebar-item,
    .sidebar-list {
      display: none;
    }

    .sidebar-burger {
      display: block;
    }
  }
}

@media (max-width: breakpoint("tablet")) {
  #sidebar {
    position: relative;
    height: fit-content;
    width: 100%;
    padding: 10px;
    border-radius: 0;

    .sidebar-header {
      margin-bottom: 1.2rem;
    }

    .sidebar-text-span {
      margin-bottom: 0;
    }

    .sidebar-list {
      display: none;
    }
  }
  .sidebar-page-wrapper {
    flex-direction: column;
    height: fit-content;
  }
  .sidebar-page-wrapper .content {
    padding-left: 0;
  }

  .register-step {
    width: 100%;
  }

  .sidebar-page-wrapper {
    padding: 8px 12px;
    height: 100vh !important;
  }

  #sidebar {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    top: 0;

    .sidebar-header {
      margin: 0 !important;
    }

    .sidebar-item,
    .sidebar-list {
      display: none;
    }

    .sidebar-burger {
      display: block;
    }
  }
}

@media (max-width: breakpoint("phone")) {
  #sidebar {
    position: relative;
    height: fit-content;
    width: 100%;
    padding: 10px;
    border-radius: 0;

    .sidebar-header {
      margin-bottom: 1.2rem;
    }

    .sidebar-text-span {
      margin-bottom: 0;
    }

    .sidebar-list {
      display: none;
    }
  }

  .sidebar-page-wrapper {
    flex-direction: column;
    height: fit-content;
  }
  .sidebar-page-wrapper .content {
    padding-left: 0;
  }

  .sidebar-page-wrapper {
    padding: 8px 12px;
    height: 100vh !important;
  }

  #sidebar {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    top: 0;

    .sidebar-header {
      margin: 0 !important;
    }

    .sidebar-item,
    .sidebar-list {
      display: none;
    }

    .sidebar-burger {
      display: block;
    }
  }
}
