.tag-list {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.tag {
  font-size: $font-size-p5;
  border-radius: 5px;
  padding: 3px;
}

.tag-round {
  border-radius: 50%;
  width: 1.5em;
  height: 1.5em;
  display: flex;
  align-items: center;
  justify-content: center;
}

@each $color-name, $color-value in $colors {
  .tag-#{$color-name} {
    background-color: $color-value;
    color: $white;
  }
}

@each $color-name, $color-value in $pastel-colors {
  .tag-#{$color-name} {
    background-color: $color-value;
    color: $black;
  }
}
