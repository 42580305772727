@media (max-width: breakpoint('laptop')) {

}

@media (max-width: breakpoint('tablet')) {

}

@media (max-width: breakpoint('phone')) {
    .schedules {
        flex-wrap: wrap;
    }

    .schedule-days {
        width: 100% !important;
    }

    .schedule-buttons {
        width: 100% !important;
        flex-direction: row !important;
    }
}