.collapse {
}

.collapse-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 15px;
  padding: 0.7rem 0.7rem 0.7rem 1.5rem;
}

.collapse-body {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}
