.form-group {
  margin: 8px 0;

  .input-subtext {
    margin: 5px 0 0 0;
  }

  .input-subtext .input-error {
    font-size: $font-size-p3;
    color: palette("danger");
  }

  .input-subtext .input-info {
    font-size: $font-size-p4;
    color: palette("light");

    @media (max-width: breakpoint("laptop")) {
      #login {
        color: white;
      }
    }
  }

  .form-img-preview {
    margin: 10px 0;
    width: 4rem;
    height: 4rem;
    object-fit: cover;
    border-radius: 15px;
  }
}

.form-input {
  display: flex;
  align-items: center;
  gap: 5px;
  width: 100%;
}

label {
  + input,
  + textarea,
  + .input-wrapper,
  + .PhoneInput,
  + .react-custom-select,
  + .otp-container {
    margin: 10px 0 0 0 !important;
  }
}

textarea,
input {
  padding: 15px 10px;
  border: none;
  width: 100%;
  font-size: $base-font-size;

  &:disabled {
    background-color: $grey-2;
  }

  &:focus,
  &:focus-visible {
    outline: none;
    box-shadow: 0 0 0 1px palette("dark");
  }
}

textarea,
input {
  border-radius: 15px !important;
}

input {
  border-radius: 0;
}

.date-input-placeholder::before {
  content: attr(data-placeholder);
  color: #aaa;
  position: absolute;
  margin-left: 5px;
}

.date-input-with-placeholder {
  position: relative;
}

.input-wrapper,
.PhoneInput {
  border-radius: 15px !important;
  border: none;
  display: flex;
  gap: 10px;
  align-items: center;

  input {
    padding: 12.5px 0 12.5px 15px;
    width: auto;
    flex: 1;
    border-radius: 15px;
    background-color: inherit;
  }

  .input-tail {
    padding-right: 15px;
  }

  &:disabled {
    background-color: $grey-2;
  }
}

textarea.resize {
  resize: both;
}

textarea.resize-vertical {
  resize: vertical;
}

textarea.resize-horizontal {
  resize: horizontal;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.input-chf {
  position: relative;
  display: inline-block;

  input {
    padding-right: 40px;
  }

  &::after {
    content: "CHF";
    position: absolute;
    right: 20px;
    top: 50%;
    width: 20px;
    height: 20px;
    transform: translateY(-50%);
    pointer-events: none;
    color: palette("dark");
  }
}

.input-percent {
  position: relative;
  display: inline-block;

  input {
    padding-right: 40px;
  }

  &::after {
    content: "%";
    position: absolute;
    right: 5px;
    top: 50%;
    width: 20px;
    height: 20px;
    transform: translateY(-50%);
    pointer-events: none;
    color: palette("dark");
  }
}

.input-km {
  position: relative;
  display: inline-block;

  input {
    padding-right: 40px;
  }

  &::after {
    content: "km";
    position: absolute;
    right: 5px;
    top: 50%;
    width: 20px;
    height: 20px;
    transform: translateY(-50%);
    pointer-events: none;
    color: palette("dark");
  }
}

.input-locked {
  position: relative;
  display: inline-block;

  input {
    padding-right: 40px;
  }

  &::after {
    content: "🔒";
    position: absolute;
    right: 5px;
    top: 50%;
    width: 20px;
    height: 20px;
    transform: translateY(-50%);
    pointer-events: none;
    color: palette("dark");
  }

  .react-select__indicators {
    display: none !important;
  }
}

.hidden-field {
  margin: 0 !important;
  height: 0 !important;

  .form-input::after {
    display: none !important;
  }

  label {
    display: none !important;
  }
}

input:disabled,
textarea:disabled,
.react-select__single-value--is-disabled {
  color: palette("light") !important;
}

// Style for react-select component
.react-custom-select {
  border-radius: 20px !important;
}

.react-select__single-value,
.react-select__placeholder {
  overflow: visible !important;
  text-overflow: clip !important;
  white-space: nowrap !important;
}

.react-select__multi-value img {
  display: none !important;
}

.react-select__control {
  background-color: palette("pastel-light") !important;
  border: none !important;
  border-radius: 15px !important;
  padding: 0 0.5rem !important;
  min-width: 125px;
  flex-wrap: nowrap !important;
  flex-grow: 1 !important;

  &.react-select__control--is-focused {
    box-shadow: 0 0 0 1px palette("dark") !important;

    & input {
      box-shadow: none !important;
    }
  }

  .react-select__value-container {
    padding: 11px 0 !important;
    font-size: $base-font-size !important;
    overflow: visible !important;
    width: fit-content !important;
  }

  .react-select__input-container {
    font-size: $font-size-p3 !important;
  }

  .react-select__multi-value {
    background-color: palette("dark") !important;
    border-radius: 5px !important;

    .react-select__multi-value__label,
    .react-select__multi-value__remove {
      color: $white !important;
    }
  }

  .react-select__indicator-separator {
    display: none !important;
  }

  .react-select__indicators {
    svg {
      transform: scale(0.7);
    }
    .react-select__indicator {
      padding: 0 !important;
    }
  }

  .react-select__dropdown-indicator {
    svg {
      color: palette("dark") !important;
    }
  }

  .react-select__value-container--is-multi + .react-select__indicators {
    .react-select__indicator.react-select__dropdown-indicator {
      display: flex;
      align-items: center;
      justify-content: center;
      width: $button-xs-height !important;
      height: $button-xs-height !important;
      margin-right: 15px;
      border-radius: 15px;
      font-size: $font-size-p6 !important;
      background-color: palette("pastel-light") !important;
      cursor: pointer;

      svg {
        color: palette("pastel-dark") !important;
      }

      &:hover {
        background-color: darken(palette("pastel-light"), 10%) !important;
        transition: ease-in-out 0.2s;

        svg {
          color: $white !important;
          transition: ease-in-out 0.2s;
        }
      }
    }
  }
}

.react-select__menu {
  z-index: 1000000000 !important;

  .react-select__menu-list {
    &::-webkit-scrollbar-track {
      background-color: palette("tertiary");
    }

    &::-webkit-scrollbar-thumb {
      background-color: palette("secondary");
      border-radius: 10px;
      &:hover {
        background-color: palette("light");
      }
    }

    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }

    // For Firefox
    scrollbar-color: palette("tertiary") palette("secondary");
    scrollbar-width: thin;
  }

  .react-select__option--is-focused {
    background-color: palette("pastel-light") !important;
  }

  .react-select__option--is-selected {
    background-color: palette("light") !important;
  }
}

.react-custom-select.dashboard-select {
  background-color: transparent !important;

  .react-select__control {
    background-color: $white !important;
    height: 45.25px !important;
    border-radius: 20px !important;
  }

  * {
    font-size: $font-size-p3 !important;
  }

  .react-select__indicator-separator {
    display: none !important;
  }

  .react-select__value-container {
    padding: 0 !important;
  }
}

.react-custom-select.bg-white {
  .react-select__control {
    background-color: $white !important;
  }
}

.react-custom-select.bg-light {
  .react-select__control {
    background-color: palette("pastel-light") !important;
  }
}

// React-OTP component
.otp-container {
  display: flex;
  gap: 1rem;
}

.otp-input {
  width: 40px !important;
  border-radius: 10px;
  border: 1px solid palette("light");
  text-align: center;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 1px palette("light");
  }
}

@media (max-width: breakpoint("laptop")) {
}

@media (max-width: breakpoint("tablet")) {
}

@media (max-width: breakpoint("phone")) {
  .form-input {
    font-size: 80%;
  }
}
