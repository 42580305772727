.alert {
  padding: 1rem;
  border-radius: 15px;
  color: $white;
}

@each $color-name, $color-value in $colors {
  .alert-#{$color-name} {
    background-color: palette($color-name) !important;
    color: palette("white") !important;
  }
}

@each $color-name, $color-value in $pastel-colors {
  $trimmed-color-name: str-slice($color-name, 8);
  .alert-#{$color-name} {
    background-color: palette($color-name) !important;
    color: palette($trimmed-color-name) !important;
  }
}
