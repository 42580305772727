#landing {
  #beta-floating {
    position: fixed;
    bottom: 30%;
    z-index: 998;
    background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 0.2)
      ),
      url("../../img/landing/3.webp");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 10px 0 0 10px;
    box-shadow: $base-elevation;
    display: flex;
    flex-direction: row;
    width: 350px;
    transition: right 0.3s ease-in-out;

    .beta-floating-content {
      padding: 1rem;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      color: white;
      flex-grow: 1;
    }

    &.show {
      right: 0;
    }

    &.hide {
      right: -335px;
    }

    &.hide:hover {
      right: 0;
    }
  }

  #landing-bar {
    position: sticky;
    height: 10vh;
    min-height: fit-content;

    #landing-bar-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 2rem 2.5rem;
    }

    .lang-button svg {
      transform: scale(1.5);
    }

    .lang-button:hover {
      cursor: pointer;
    }

    .lang-button .react-select__control--is-focused {
      box-shadow: none !important;
    }

    .lang-button .react-select__indicator {
      display: none;
    }
  }

  .landing-section {
    padding: 4rem 3rem;
    text-align: center;

    h1 {
      font-size: 3.5rem;
      margin: 0;
      font-weight: normal;
    }

    h2 {
      font-size: 1.5rem;
      margin: 0;
      font-weight: normal;
    }

    &#section-header {
      min-height: fit-content;
      height: 50vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 3rem;

      h1 {
        font-size: 4.5rem;
        margin: 0;
        font-weight: normal;
      }

      h2 {
        font-size: 1.5rem;
        margin: 0;
        font-weight: normal;
      }
    }

    &#landing-image-deck {
      position: relative;
      min-height: 40vh;
      height: 40vh;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      overflow: hidden;

      img {
        position: absolute;
        bottom: 0;
        width: 33%;
        object-fit: cover;
        border-radius: 15px 15px 0 0;

        &:nth-child(1) {
          left: 0;
          z-index: 1;
          height: 60%;
        }

        &:nth-child(2) {
          left: 15%;
          z-index: 2;
          height: 80%;
        }

        &:nth-child(3) {
          left: 50%;
          z-index: 3;
          transform: translateX(-50%);
          height: 100%;
        }

        &:nth-child(4) {
          right: 15%;
          z-index: 2;
          height: 80%;
        }

        &:nth-child(5) {
          right: 0;
          z-index: 1;
          height: 60%;
          object-position: bottom left;
        }
      }
    }

    &#section-solution {
      min-height: 90vh;
      height: fit-content;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 3rem;
      background-color: palette("pastel-danger");

      .resto-img,
      .company-img,
      .customer-img {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        text-align: left;
        height: 450px;
        background-size: cover;
        color: white;
        padding: 1rem;
        transition: transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
        will-change: transform, box-shadow;
        border-radius: 10px;
      }

      .resto-img:hover,
      .company-img:hover,
      .customer-img:hover {
        transform: translateY(-10px);
        box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.3);
        cursor: pointer;
      }

      .resto-img {
        background-position: center;
        background-image: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0),
            rgba(0, 0, 0, 1)
          ),
          url("../../img/landing/resto.webp");
      }

      .company-img {
        background-image: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0),
            rgba(0, 0, 0, 0.9)
          ),
          url("../../img/landing/company.webp");
      }

      .customer-img {
        background-position: bottom left;
        background-image: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0),
            rgba(0, 0, 0, 0.9)
          ),
          url("../../img/landing/customer.webp");
      }
    }

    &#section-customer-todaysspecial {
      min-height: 70vh;
      height: 70vh;
      display: flex;
      padding: 0;
      text-align: left;
      background-color: palette("pastel-danger");

      .left-side {
        height: 100%;
        width: 50%;
        display: flex;
        flex-direction: column;
      }

      .right-side {
        width: 50%;
        display: flex;
        flex-direction: column;
        padding: 4rem 3rem;
      }

      .left-side img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &#section-customer-consumption,
    &#section-company-values {
      min-height: 70vh;
      height: 70vh;
      display: flex;
      padding-left: 10rem;
      padding-right: 10rem;
      text-align: left;
      padding: 0;

      h1 {
        line-height: 3.2rem;
      }

      .left-side {
        height: 100%;
        width: 50%;
        display: flex;
        flex-direction: column;
        padding: 4rem 3rem;
      }

      .right-side {
        width: 50%;
        display: flex;
        flex-direction: column;
      }

      .right-side img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .left-side img {
        height: 40px;
        width: auto;
        vertical-align: baseline;
      }
    }

    &#section-company-break {
      min-height: 45vh;
      height: 45vh;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;

      .company-img-container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 5rem;
        text-align: left;
      }

      .company-img {
        background-image: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0.5),
            rgba(0, 0, 0, 0)
          ),
          url("../../img/landing/company-break.webp");
        background-size: cover;
        background-position: center;
        width: 100%;
        height: 100%;
        position: relative;
      }
    }

    &#section-partners {
      padding: 1rem 0 !important;
      display: none;
      flex-direction: column;
      gap: 1rem;
      justify-content: center;
      align-items: center;
      color: palette("pastel-dark");

      h3 {
        margin: 0;
      }

      .partners {
        display: flex;
        gap: 3rem;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
      }

      .partners img {
        max-height: 150px;
        width: auto;
      }
    }

    &#section-restaurant-values {
      min-height: fit-content;
      height: fit-content;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 3rem;

      h1 {
        font-size: 3.5rem;
        margin: 0;
        font-weight: normal;
        line-height: 3.2rem;
      }

      h3 {
        font-size: 1.5rem;
        margin: 0;
        font-weight: normal;
      }

      .card {
        padding: 0;
        height: 400px;
        background-color: palette("pastel-danger");
      }

      img {
        width: 200px;
        height: 100%;
        border-radius: 10px 0 0 10px;
      }
    }

    &#section-restaurant-process {
      min-height: 75vh;
      height: 75vh;
      display: flex;
      gap: 1rem;
      background-color: palette("pastel-danger");

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
      }

      .restaurant-process {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: left;
      }

      h4 {
        margin: 0;
      }
    }
  }
}

.landing-half {
  width: 50%;
  display: flex;
}

.store-button {
  display: flex;
  gap: 8px;
  border-radius: 8px;
  padding: 12px 10px;

  p {
    margin: 0;
    padding: 0;
  }
}

.card-deck {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 1rem;

  img {
    width: 100%;
    height: 180px;
    object-fit: cover;
  }
}

.landing-step {
  .landing-step-content {
    padding: 0;
    display: flex;
    overflow: hidden;
    transition: max-height 0.3s ease-out, max-height 0.3s ease-in;
  }

  .circle-around {
    border-radius: 50%;
    font-size: 80%;
    width: 1.5em;
    height: 1.5em;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .landing-step-header {
    color: palette("pastel-dark");
  }

  &.expanded {
    .landing-step-header {
      color: palette("dark");

      h3 {
        font-weight: bold;
      }
    }

    .landing-step-content {
      max-height: 500px;
    }

    .circle-around {
      background-color: palette("pastel-danger");
      color: palette("dark");
    }
  }

  &.collapsed {
    .landing-step-content {
      max-height: 0 !important;
    }

    .circle-around {
      background-color: palette("dark");
      color: white;
    }
  }
}

.timeline {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-left: 40px;
  margin: 20px 0;
  gap: 2rem;
}

.timeline-item {
  display: flex;
  align-items: flex-start;
  position: relative;
  margin-bottom: 40px;

  &:last-child {
    margin-bottom: 0;
  }

  .timeline-icon {
    position: relative;
    z-index: 1;
    background-color: palette("danger");
    width: 30px;
    height: 30px;
    min-width: 30px;
    min-height: 30px;
    border-radius: 50%;
    margin-right: 20px;
    border: 4px solid #fff;
    box-shadow: 0 0 0 2px #f5f5f5;
  }

  &:not(:last-child) .timeline-icon::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 30px;
    width: 2px;
    height: calc(100% + 3rem);
    background: repeating-linear-gradient(
      0deg,
      #e74c3c,
      #e74c3c 2px,
      transparent 2px,
      transparent 4px
    );
    transform: translateX(-50%);
  }

  .timeline-content {
    padding-left: 10px;
    h4 {
      margin: 0.5rem 0;
      font-weight: normal;
    }

    p {
      margin: 0;
    }
  }
}

// Styles for medium screens (laptops)
@media (max-width: breakpoint("laptop")) {
  .landing-section {
    padding: 2rem 3rem !important;

    h1 {
      font-size: 2.3rem !important;
      line-height: 2rem !important;
    }
  }

  #section-header {
    h1 {
      font-size: 3.5rem !important;
      line-height: 3.5rem !important;
    }

    h2 {
      font-size: 1.3rem !important;
    }
  }

  #section-solution {
    min-width: fit-content !important;
    height: fit-content !important;

    .card-deck {
      flex-wrap: wrap;
      margin-bottom: 0 !important;
    }

    .resto-img,
    .company-img,
    .customer-img {
      width: 100% !important;
    }
  }

  #section-customer-consumption {
    min-height: 700px !important;
    height: 700px !important;
    padding: 0 !important;

    h1 {
      margin: 0 0 1rem 0 !important;
      line-height: 3.2rem;
    }

    .left-side {
      padding: 2rem 3rem !important;
      width: 60% !important;
    }

    .right-side {
      width: 40% !important;
    }

    .left-side img {
      height: 25px !important;
    }
  }

  #section-customer-todaysspecial {
    min-height: 700px !important;
    height: 700px !important;
    padding: 0 !important;

    h1 {
      margin: 0 0 1rem 0 !important;
      line-height: 3.2rem;
    }

    .left-side {
      width: 40% !important;
    }

    .right-side {
      padding: 2rem 3rem !important;
      width: 60% !important;
    }

    .store-button {
      margin-top: 1rem !important;
    }
  }

  #section-restaurant-values {
    gap: 1.5rem !important;

    h1 {
      margin: 0 0 1rem 0 !important;
      line-height: 3.2rem;
    }

    .card-deck {
      flex-wrap: wrap;
    }

    .card {
      width: 100% !important;
      height: fit-content !important;
    }

    img {
      height: 300px !important;
    }
  }

  #section-restaurant-process {
    min-height: 600px !important;
    height: 600px !important;

    .card {
      width: 50% !important;
    }

    .timeline-icon::after {
      height: calc(100% + 4.5rem) !important;
    }
  }

  #section-company-break {
    padding: 0 !important;
    min-height: 300px !important;
    height: 300px !important;
  }

  #section-company-values {
    padding: 0 !important;
    min-height: fit-content !important;
    height: fit-content !important;

    .left-side {
      padding: 2rem 3rem !important;
      width: 60% !important;
    }

    .right-side {
      width: 40% !important;
    }

    button {
      margin-top: 1rem !important;
    }
  }
}

// Styles for small screens (tablets)
@media (max-width: breakpoint("tablet")) {
  #section-header {
    h1 {
      font-size: 2.7rem !important;
    }

    h2 {
      font-size: 1.2rem !important;
    }
  }

  #landing-bar-content {
    padding: 8px !important;

    button {
      font-size: 0.8rem !important;
    }
  }

  .landing-section {
    padding: 35px !important;

    h1 {
      font-size: 2.5rem !important;
      line-height: 2.2rem !important;
    }

    h2 {
      font-size: 1.6rem !important;
    }
  }

  .store-button {
    flex-direction: row;
    padding: 4px 8px;
  }

  .card-deck {
    flex-direction: column;
    .card {
      width: 100%;
      margin-bottom: 12px;
    }
  }

  #section-solution {
    height: fit-content !important;

    .card-deck {
      margin-bottom: 0 !important;
    }

    .customer-img,
    .company-img,
    .resto-img {
      height: 300px !important;
    }
  }

  #section-customer-consumption {
    max-height: fit-content !important;
    height: fit-content !important;
    padding: 0 !important;

    .landing-steps {
      flex-direction: column !important;
      gap: 0 !important;
    }

    .store-button {
      display: none !important;
    }

    .left-side,
    .right-side {
      width: 100% !important;
      margin: 0;
    }

    .left-side {
      padding: 35px !important;
    }

    .left-side img {
      height: 30px !important;
    }

    img {
      height: 350px !important;
    }
  }

  #section-customer-todaysspecial {
    min-height: fit-content !important;
    height: 600px !important;
    padding: 0 !important;
    flex-direction: column-reverse !important;

    .left-side {
      width: 100% !important;
    }

    .right-side {
      padding: 35px !important;
      width: 100% !important;
    }
  }

  #section-restaurant-values {
    min-height: fit-content !important;
    height: fit-content !important;
    gap: 0 !important;

    .card {
      height: 300px !important;
      width: 100% !important;
    }
  }

  #section-restaurant-process {
    min-height: fit-content !important;
    height: fit-content !important;
    .restaurant-process {
      padding: 0 !important;
    }

    .timeline {
      gap: 0.5rem !important;
    }

    h4 {
      margin: 0 !important;
    }
  }

  #section-company-break {
    min-height: 250px !important;
    height: 250px !important;

    .company-img-container {
      text-align: left !important;
      padding: 35px !important;
    }
  }

  #section-company-values {
    min-height: fit-content !important;
    height: fit-content !important;
    flex-direction: column !important;

    .left-side,
    .right-side {
      width: 100% !important;
    }
  }
}

// Styles for extra small screens (smartphones)
@media (max-width: breakpoint("phone")) {
  #beta-floating {
    border-radius: 10px !important;
    width: 95% !important;
    position: fixed;
    right: 0.5rem !important;
    transition: bottom 0.3s ease-in-out !important;

    &.show {
      bottom: 0.5rem !important;
    }

    &.hide {
      bottom: -130px !important;
    }
  }

  #landing-bar-content {
    button {
      font-size: 0.8rem !important;
      padding: 4px 8px !important;
    }

    .react-select__control {
      min-width: 0;
    }

    .react-select__menu {
      width: fit-content;
    }

    .react-select__menu * {
      overflow: visible;
    }
    svg {
      transform: scale(1.2) !important;
    }
  }

  #section-header {
    h1 {
      font-size: 2rem !important;
      line-height: 1.8rem !important;
    }

    h2 {
      font-size: 1rem !important;
    }
  }

  #landing-bar-content {
    padding: 10px !important;
  }

  .store-button {
    padding: 3px 5px;
    svg {
      transform: scale(0.9) !important;
    }
  }

  .card-deck {
    flex-direction: column;
    .card {
      width: 100%;
      margin-bottom: 10px;
    }
  }

  .landing-section {
    padding: 20px !important;

    h1 {
      font-size: 1.5rem !important;
      line-height: 1.5rem !important;
    }

    h2 {
      font-size: 1rem !important;
    }
  }

  #landing-image-deck img {
    width: 100%;
    margin-bottom: 6px;
  }

  #section-header {
    justify-content: flex-start !important;
    gap: 50px !important;
    margin-top: 20px !important;
  }

  #landing-image-deck {
    img {
      width: 40% !important;
    }

    img:nth-child(2),
    img:nth-child(4) {
      display: none;
    }

    img:nth-child(3) {
      height: 100% !important;
      width: 60% !important;
    }

    img:nth-child(1),
    img:nth-child(5) {
      height: 80% !important;
    }
  }

  #section-solution {
    min-height: fit-content !important;
    max-height: fit-content !important;
    height: fit-content !important;
    gap: 20px !important;

    .card-deck {
      padding: 0 !important;
    }

    .resto-img,
    .company-img,
    .customer-img {
      height: 200px !important;
    }
  }

  #section-customer-consumption {
    max-height: fit-content !important;
    height: fit-content !important;

    .landing-steps {
      flex-direction: column !important;
      gap: 0 !important;
    }

    .left-side,
    .right-side {
      width: 100% !important;
      margin: 0;
    }

    .left-side {
      padding: 20px !important;
    }

    .left-side img {
      height: 20px !important;
    }

    .right-side img {
      width: 100%;
      height: 250px !important;
      margin-top: 6px;
    }

    .store-button {
      display: none;
    }

    .landing-step-header h3 {
      padding: 5px !important;
      gap: 10px !important;
    }

    .landing-step-content {
      text-align: left !important;
    }
  }

  #section-customer-todaysspecial {
    max-height: fit-content !important;
    height: fit-content !important;

    .left-side img {
      width: 100% !important;
      height: 250px !important;
      margin-top: 6px;
    }
  }

  #section-company-break {
    min-height: fit-content !important;
    max-height: fit-content !important;
    height: fit-content !important;
    text-align: center !important;

    .company-img-container {
      padding: 20px !important;
    }

    button {
      width: 100% !important;
    }
  }

  #section-restaurant-values {
    min-height: fit-content !important;
    max-height: fit-content !important;
    height: fit-content !important;
    .card {
      flex-direction: column !important;
      width: 100% !important;
      gap: 10px !important;
      height: fit-content !important;
    }
    p {
      margin: 0 !important;
    }
    img {
      height: 200px !important;
      width: 100% !important;
    }
  }

  #section-restaurant-process {
    min-height: fit-content !important;
    max-height: fit-content !important;
    height: fit-content !important;
    flex-direction: column !important;
    padding: 0 !important;

    img {
      border-radius: 0 !important;
    }

    .landing-half {
      width: 100% !important;
      flex-direction: column !important;
      gap: 10px !important;
    }

    .restaurant-process {
      padding: 10px !important;
    }

    .timeline {
      text-align: left !important;
      padding: 0 !important;
      margin: 0 !important;
      gap: 0 !important;
    }

    .timeline-item {
      margin-bottom: 30px !important;
    }

    .timeline-icon::after {
      height: calc(100%) !important;
    }
  }

  #section-company-break {
    min-height: 200px !important;
    height: 200px !important;

    button {
      padding: 10px !important;
    }
  }
}
