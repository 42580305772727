#login {
    display: flex;
}

.login-illustration {
    width: 50%;
    height: 100vh;
    background-image: url('../../img/login.webp');
    background-size: cover;
    background-position: 10%;
    background-repeat: no-repeat;
}

.login-content {
    width: 50%;
    padding: 3rem;
    max-height: 100vh !important;

    h1 {
        margin: 30px 0;
    }
}

.login-form {
    padding-top: 1rem;

    button {
        margin-top: 2rem;
    }

    > div  {
        margin-top: 1rem;
    }
}

@media (max-width: breakpoint('laptop')) {
    #login {
        display: block;
    }

    .login-illustration {        
        width: 100%;
        filter: brightness(0.4);
        background-position: center;
    }

    .login-content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 65%;
        border-radius: 10px;
        padding: 50px;
        background: linear-gradient(135deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.2));
        backdrop-filter: blur(15px);
    }
}

@media (max-width: breakpoint('tablet')) {
    .login-content {
        width: 80%;

        h1 {
            font-size: 1.5rem;
        }

        .p1 {
            font-size: 1rem !important; 
        }
    }
}

@media (max-width: breakpoint('phone')) {
    .login-content {
        width: 100%;
        padding: 20px;
    }
}