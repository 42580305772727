$gap-base: .5rem;

.block {
    display: block;
}

.flex {
    display: flex;
}

.flex-grow {
    flex-grow: 1;
}

.flex-wrap {
    flex-wrap: wrap;
}

.gap-1 {
    gap: $gap-base;
}

.gap-2 {
    gap: calc(2 * #{$gap-base});
}

.gap-3 {
    gap: calc(3 * #{$gap-base});
}

.gap-4 {
    gap: calc(6 * #{$gap-base});
}

.row {
    display: flex;
    flex-wrap: wrap;
}

.column {
    display: flex;
    flex-direction: column;    
}

.col {
    flex: 1;
}

.col-1 {
    flex: 0 0 8.333333%;
}

.col-2 {
    flex: 0 0 16.666667%;
}

.col-3 {
    flex: 0 0 25%;
}

.col-4 {
    flex: 0 0 33.333333%;
}

.col-5 {
    flex: 0 0 41.666667%;
}

.col-6 {
    flex: 0 0 50%;
}

.col-7 {
    flex: 0 0 58.333333%;
}

.col-8 {
    flex: 0 0 66.666667%;
}

.col-9 {
    flex: 0 0 75%;
}

.col-10 {
    flex: 0 0 83.333333%;
}

.col-11 {
    flex: 0 0 91.666667%;
}

.col-12 {
    flex: 0 0 100%;
}

.w-100 {
    width: 100%;
}

.w-90 {
    width: 90%;
}

.w-80 {
    width: 80%;
}

.w-70 {
    width: 70%;
}

.w-60 {
    width: 60%;
}

.w-50 {
    width: 50%;
}

.w-40 {
    width: 40%;
}

.w-30 {
    width: 30%;
}

.w-20 {
    width: 20%;
}

.w-10 {
    width: 10%;
}

.h-100 {
    height: 100%;
}

.h-90 {
    height: 90%;
}

.h-80 {
    height: 80%;
}

.h-70 {
    height: 70%;
}

.h-60 {
    height: 60%;
}

.h-50 {
    height: 50%;
}

.h-40 {
    height: 40%;
}

.h-30 {
    height: 30%;
}

.h-20 {
    height: 20%;
}

.h-10 {
    height: 10%;
}

.fit-content {
    width: fit-content !important;
}

.justify-around {
    justify-content: space-around;
}

.justify-between {
    justify-content: space-between;
}

.justify-start {
    justify-content: start;
}

.justify-end {
    justify-content: flex-end;
}

.justify-center {
    justify-content: center;
}

.align-center {
    align-items: center;
}

.align-start {
    align-items: flex-start;
}

.align-end {
    align-items: flex-end;
}

.self-align-center {
    align-self: center;
}

.self-align-end {
    align-self: flex-end;
}