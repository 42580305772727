.card {
    padding: .8rem;
    border-radius: 10px;
    background-color: palette('white');
    text-align: left;
    box-shadow: $base-elevation;
}

.partner-card {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top: .5rem;
    padding: 1rem;
    border-radius: 10px;
    background-color: palette('white');

    img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
    }

    .partner-card-content {
        p {
            margin: 0;
        }
    }
}