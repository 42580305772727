
@media (max-width: breakpoint('laptop')) {
    .tabs {
        overflow-x: auto;
        padding-bottom: 10px;
    }
    
    .tab-btn {
        min-width: fit-content;
    }
}

@media (max-width: breakpoint('tablet')) {
    .tabs {
        overflow-x: auto;
        padding-bottom: 10px;
    }
    
    .tab-btn {
        min-width: fit-content;
    }
}

@media (max-width: breakpoint('phone')) {
    .tabs {
        overflow-x: auto;
        padding-bottom: 8px;
    }
    
    .tab-btn {
        min-width: fit-content;
    }
}