.step-app-preview-wrapper {
  padding: 3.5rem 3.5em 1.5rem 3.5rem;
  border-radius: 15px;
  background: palette("pastel-light");
  text-align: center;
  width: fit-content;

  .add-button {
    display: flex;
    padding-top: 1.5rem;
    align-items: center;
    justify-content: center;
  }
}

.app-preview-card {
  width: 325px;
  height: 335px;
  border-radius: 15px;
  box-shadow: $base-elevation;
  overflow: hidden;
  text-align: left;

  .app-preview-img {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 5px;
    width: 100%;
    height: 75%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    .custom-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .app-preview-text {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    width: 100%;
    height: 25%;
    padding: 15px;
    background-color: $white;

    h4 {
      margin: 0;
      max-width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}

.list-preview-img {
  width: 50px;
  height: 50px;
  border-radius: 10px;
  object-fit: cover;
}

.todayspecial-preview-card {
  position: relative;
  width: 325px;
  height: 85%;
  min-height: 335px;
  border-radius: 15px;
  box-shadow: $base-elevation;
  overflow: hidden;
  text-align: left;

  .todayspecial-preview-img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    .custom-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top center;
    }
  }

  .todayspecial-preview-text {
    position: absolute;
    background-color: palette("danger") !important;
    color: white;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);
    width: 92%;
    height: 40%;
    padding: 15px;
    background-color: $white;
    border-radius: 15px;

    .todayspecial-text-section {
      display: flex;
      flex-direction: column;
      border-left: 1px solid $white;
      padding-left: 10px;
      margin-left: 30%;
      height: 100%;
    }

    h4 {
      margin: 0;
      font-weight: normal;
    }
  }
}

.product-preview-card {
  width: 325px;
  height: 335px;
  border-radius: 15px;
  box-shadow: $base-elevation;
  overflow: hidden;
  text-align: left;

  .product-preview-img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 75%;

    .custom-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .product-preview-text {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    width: 100%;
    height: 25%;
    padding: 15px;
    background-color: $white;

    h4 {
      margin: 0;
      max-width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}

@media (max-width: breakpoint("small-screen")) {
}

@media (max-width: breakpoint("laptop")) {
  .app-preview-card {
    width: 250px;
    height: 250px;
  }

  .app-preview-text {
    padding: 8px !important;
  }

  .app-preview-text h4 {
    font-size: 1rem;
  }
}

@media (max-width: breakpoint("tablet")) {
  .step-app-preview-wrapper {
    padding: 2rem 2em 1rem 2em;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: breakpoint("phone")) {
}
