.Toastify__progress-bar-theme--light {
    background-color: map-get($colors, 'light') !important;
}
.Toastify__progress-bar-theme--dark {
    background-color: map-get($colors, 'dark') !important;
}
.Toastify__progress-bar--info {
    background-color: map-get($colors, 'info') !important;
}
.Toastify__progress-bar--success {
    background-color: map-get($colors, 'success') !important;
}
.Toastify__progress-bar--warning {
    background-color: map-get($colors, 'warning') !important;
}
.Toastify__progress-bar--error {
    background-color: map-get($colors, 'danger') !important;
}

.Toastify__toast--light svg {
    fill: map-get($colors, 'light') !important;
}
.Toastify__toast--dark svg {
    fill: map-get($colors, 'dark') !important;
}
.Toastify__toast--info svg {
    fill: map-get($colors, 'info') !important;
}
.Toastify__toast--success svg {
    fill: map-get($colors, 'success') !important;
}
.Toastify__toast--warning svg {
    fill: map-get($colors, 'warning') !important;
}
.Toastify__toast--error svg {
    fill: map-get($colors, 'danger') !important;
}