.table-container {
    height: 100%;

    &::-webkit-scrollbar-track {
        background-color: palette('tertiary');
    }

    &::-webkit-scrollbar-thumb {
        background-color: palette('secondary');
        border-radius: 50px;
        &:hover {
            background-color: palette('light');
        }
    }

    &::-webkit-scrollbar {
        width: 7px;
        height: 7px;
    }
}

thead {
    position: sticky;
    top: 0;
    background-color: palette('white');
}

table {
    border-collapse: collapse;
}

th {
    padding: 1.5rem 1rem;
    text-align: left;
    font-weight: normal;

    &:first-child {
        text-align: right;
    }
}

td {
    padding: 3rem 1rem;
    &:first-child {
        text-align: right;
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
    }

    &:last-child {
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
    }
}

tr.clickable-row {
    cursor: pointer;
    &:hover {
        background-color: palette('pastel-light');
    }
}

tr.expanded-row {
    background-color: palette('pastel-light');
}

.draggable-item--is-dragging {
    display: flex;
    justify-content: space-between;
    background-color: palette('pastel-light');
    border-radius: 50px;
    padding: 0 1.5rem;
}

.drag-handle--disabled {
    cursor: not-allowed;

    svg {
        color: palette('pastel-light');
    }
}

.table-collapse > td {  
    text-align: left;
    background-color: palette('pastel-light');
    box-shadow: inset 0 0 4px 0 palette('pastel-dark');
}

.company-partners-tables {
    th,
    td {
        padding: 20px 12px !important;
    }
}

@media (max-width: breakpoint('laptop')) {

}

@media (max-width: breakpoint('tablet')) {
    .table-container {
        max-height: fit-content;
        height: fit-content;
    }

    table {
        th,
        td {
            padding: 20px 12px !important;
        }
    }
}

@media (max-width: breakpoint('phone')) {
    .table-container {
        max-height: fit-content;
        height: fit-content;
    }

    table {
        th,
        td {
            padding: 8px 4px !important;
        }
    }
}