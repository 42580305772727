.content {
    position: relative;
    width: 100%;
    min-height: 86vh;
    margin: 0 8rem;
}

.border-rounded {
    border-radius: 15px;
}

@media (max-width: breakpoint('tablet')) {
    .content {
        margin: 0 !important;
        padding: 10px !important;
    }
}

@media (max-width: breakpoint('phone')) {
    .content {
        margin: 0 !important;
        padding: 10px !important;
    }
}