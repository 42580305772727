.dropdown-container {
    position: relative;

    &.dropdown-opened button {
        background-color: darken(palette('pastel-light'), 4);
    }    
}

.dropdown-menu {
    z-index: 99999;
    background-color: palette('white');
    min-width: 160px;
    box-shadow: $base-elevation;
    overflow: hidden;
    font-size: $font-size-p3;
    border-radius: 15px;

    .dropdown-item {
        color: black;
        padding: 12px;
        text-decoration: none;
        display: block;
        cursor: pointer;

        &:hover {
            background-color: palette('pastel-light');
        }
    }
}