#footer {
    min-height: 80vh;
    height: fit-content;
    background-color: palette('danger') ;
    display: flex;
    flex-direction: column;
    padding: 3rem 2rem 1rem 2rem;
    color: white;
    gap: 2rem;

    img {
        max-height: 40vh;
    }
}

@media (max-width: 480px) {
    #footer {
        height: fit-content !important;
        gap: 30px; 
        
        & > div.flex {
            flex-direction: column !important;
            gap: 20px !important;
            margin-top: 0 !important;
        }
    }
}

@media (max-width: 768px) {
    #footer {
        height: fit-content !important;
        gap: 30px; 
        
        & > div:nth-child(1).flex {
            flex-direction: column !important;
            gap: 20px !important;
            margin-top: 0 !important;
        }

        & > div:nth-child(2) {
            margin-top: 0 !important;
        }
    }
}