@mixin button-variants(
  $bg-color: $black,
  $text-color: white,
  $outlined: false
) {
  @if $outlined {
    border-color: $text-color;
    background-color: transparent;
    color: $text-color;
  } @else {
    border-color: transparent;
    background-color: $bg-color;
    color: $text-color;
  }

  &:hover,
  &.btn-checked {
    background-color: darken($bg-color, 10%);
    color: $white;
  }

  &.btn-checked-accent,
  &.btn-checked-accent:hover {
    background-color: darken($bg-color, 80%);
    color: $white !important;

    a {
      color: $white !important;
    }
  }

  &:disabled {
    background-color: palette("pastel-light");
    color: palette("light");
    cursor: not-allowed;
  }
}

@mixin button-sizes($size: "md") {
  $height: $button-base-height;
  $px: 20px;

  @if $size == "sm" {
    $height: $button-sm-height;
  } @else if $size == "xs" {
    $height: $button-xs-height;
    $px: 0px;
  } @else if $size == "lg" {
    $height: $button-lg-height;
    $px: 35px;
  }
  padding: 0 $px;
  height: $height;
}
