// Generate basic styles for buttons
@each $color-name, $color-value in $colors {
  .btn-#{$color-name} {
    @include button-variants(
      palette("pastel-" + $color-name),
      palette($color-name)
    );
  }
  .btn-#{$color-name}-outlined {
    @include button-variants(
      palette("pastel-" + $color-name),
      palette($color-name),
      true
    );
  }
}

@each $color-name, $color-value in $buttons-bg {
  $text-color: map-get($buttons-text, $color-name);
  .btn-#{$color-name} {
    @include button-variants($color-value, $text-color);
  }
  .btn-#{$color-name}-outlined {
    @include button-variants($color-value, $text-color, true);
  }
}

@each $size-name,
  $size-value in ("xs": "xs", "sm": "sm", "md": "md", "lg": "lg")
{
  .btn-#{$size-value} {
    @include button-sizes($size-value);
  }
}

button,
.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 20px;
  border-width: 1px;
  border-style: solid;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.btn-floating {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 1000;
}

.btn-transparent {
  background-color: transparent;
  border-color: transparent;
}

.btn-link {
  text-decoration: none;
  border: none;
  background-color: transparent;
  padding: 0;
  align-self: self-start;
  border-radius: 0;

  &.true-link {
    padding: 0;
    text-decoration: underline;
  }

  &:hover {
    background-color: none;
    text-decoration: underline;
  }

  &.link-sm {
    font-size: $font-size-p3;
  }

  &.link-md {
    font-size: $font-size-p2;
  }

  &.link-lg {
    font-size: $font-size-p1;
  }
}

.btn-rounded {
  align-items: center;
  justify-content: center;
  width: $button-base-height;
  height: $button-base-height;
  border-radius: 50%;
  padding: 0;

  &.btn-xs {
    width: $button-xs-height;
    height: $button-xs-height;
    font-size: $font-size-p6;
  }

  &.btn-sm {
    width: $button-sm-height;
    height: $button-sm-height;
  }

  &.btn-lg {
    font-size: $font-size-p1;
  }
}

.btn-icon {
  padding: 5px;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: fit-content;
  border-radius: 50%;
}

.btn-block {
  width: 100%;
  text-align: center;
}

.btn-tertiary,
.btn-white {
  &:hover,
  &.btn-checked {
    color: $black !important;
  }
}

.btn-group {
  display: flex;
  gap: 1rem;
}

.btn-group-vertical {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.btn-group-horizontal {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.btn-radio {
  display: none;
}

.btn-label {
  cursor: pointer;
  transition: background-color 0.3s ease;
}

@media (max-width: breakpoint("small-screen")) {
  .add-btn {
    align-items: center;
    justify-content: center;
    width: $button-sm-height;
    height: $button-sm-height;
    border-radius: 50%;
  }

  .add-btn span {
    display: none;
  }
}

@media (max-width: breakpoint("laptop")) {
  .add-btn {
    align-items: center;
    justify-content: center;
    width: $button-sm-height;
    height: $button-sm-height;
    border-radius: 50%;
  }

  .add-btn span {
    display: none;
  }
}

@media (max-width: breakpoint("tablet")) {
  .add-btn {
    align-items: center;
    justify-content: center;
    width: $button-sm-height;
    height: $button-sm-height;
    border-radius: 50%;
  }

  .add-btn span {
    display: none;
  }
}

@media (max-width: breakpoint("phone")) {
  button {
    padding-inline-start: 10px !important;
    padding-inline-end: 10px !important;
  }

  .add-btn {
    align-items: center;
    justify-content: center;
    width: $button-sm-height;
    height: $button-sm-height;
    border-radius: 50%;
  }

  .add-btn span {
    display: none;
  }
}
