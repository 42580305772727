.mobile-sidebar-overlay {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2000;
  display: flex;
  justify-content: flex-start;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease;

  &.open {
    opacity: 1;
    visibility: visible;
  }

  .mobile-sidebar {
    background: #fff;
    width: 85%;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
    padding: 1rem;
    transform: translateX(-100%);
    transition: transform 0.3s ease;
  }

  &.open {
    .mobile-sidebar {
      transform: translateX(0);
    }
  }
}

.mobile-sidebar-header {
  display: flex;
  justify-content: space-between;

  .close-btn {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
}

.mobile-sidebar-list {
  flex-grow: 1;
  margin-top: 10px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.mobile-sidebar-footer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.mobile-sidebar-item {
  display: block !important;

  .sidebar-link {
    padding: 15px !important;
  }
}

@media (min-width: breakpoint("laptop")) {
  .mobile-sidebar-overlay {
    display: none;
  }
}
