html,
body {
  height: 100vh;
}

*,
*::before,
*::after {
  box-sizing: border-box !important;
}

html,
body * {
  font-family: "Satoshi", sans-serif;
}

body {
  margin: 0;
  background-color: $bg-primary;
}

::-webkit-scrollbar-track {
  background-color: palette("tertiary");
}

::-webkit-scrollbar-thumb {
  background-color: palette("secondary");
  border-radius: 50px;
  &:hover {
    background-color: palette("light");
  }
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

a {
  color: $black;
}

.flex-column {
  flex-direction: vertical;
}

.float-top {
  position: absolute;
  top: 0;
}

.float-right {
  position: absolute;
  right: 0;
}

.float-bottom {
  position: absolute;
  bottom: 0;
}

.float-left {
  position: absolute;
  left: 0;
}

.hidden {
  display: none !important;
}

.text-white {
  color: $white;
}

.bg-white {
  background-color: $white;
}

.rounded {
  border-radius: 15px;
}

.very-rounded {
  border-radius: 50px !important;
}

.overflow-y-scroll {
  overflow-y: auto;
}

.cursor-pointer:hover {
  cursor: pointer;
}

.img-cover {
  object-fit: cover;
}

.img-contain {
  object-fit: contain;
}

.border-1 {
  border: 1px solid palette("pastel-dark");
}

.no-shadow {
  box-shadow: none !important;
}

.drag-over,
.no-drag-over {
  border-radius: 15px;
  transition: border 0.1s ease;
  padding: 0.3rem;
}

.drag-over {
  border: 3px dashed palette("pastel-dark");
}

.no-drag-over {
  border: 3px solid transparent;
}

// Generate basic styles for bg, text, border and buttons
@each $color-name, $color-value in $colors {
  .bg-#{$color-name} {
    background-color: palette($color-name) !important;
  }
  .text-#{$color-name} {
    color: palette($color-name) !important;
  }
  .border-#{$color-name} {
    border-color: palette($color-name) !important;
  }
}

@each $color-name, $color-value in $pastel-colors {
  .bg-#{$color-name} {
    background-color: palette($color-name) !important;
  }
  .text-#{$color-name} {
    color: palette($color-name) !important;
  }
  .border-#{$color-name} {
    border-color: palette($color-name) !important;
  }
}

@media (max-width: breakpoint("laptop")) {
  ::-webkit-scrollbar {
    width: 1px;
    height: 1px;
  }
}
