// Main Color Palette
$pomegranate: #f2461d;
$sunglow: #ffc117;
$mintgreen: #1ae170;
$persianindigo: #500089;

// Pastel Tones
$fairpink: #ffeae5;
$solitaire: #fef5e6;
$snowymint: #deffe9;
$purplechalk: #f4e9fe;

// Neutral
$white: #ffffff;
$grey-1: #f6f6f6;
$grey-2: #dedede;
$grey-3: #aeaeae;
$grey-4: #7f7f7f;
$grey-5: #404040;
$black: #000000;

// List of color with meaningful names
$colors: (
  "danger": $pomegranate,
  "warning": $sunglow,
  "success": $mintgreen,
  "info": $persianindigo,
  "light": $grey-4,
  "medium": $grey-3,
  "dark": $black,
);

$pastel-colors: (
  "pastel-danger": $fairpink,
  "pastel-warning": $solitaire,
  "pastel-success": $snowymint,
  "pastel-info": $purplechalk,
  "pastel-light": $grey-1,
  "pastel-medium": $grey-2,
  "pastel-dark": $grey-4,
);

// Buttons settings
$button-base-height: 62px;
$button-xs-height: $button-base-height * 0.5;
$button-sm-height: $button-base-height * 0.73;
$button-lg-height: $button-base-height * 1.3;

$buttons-bg: (
  "primary": $black,
  "secondary": $grey-3,
  "tertiary": $grey-1,
  "white": $white,
);

$buttons-text: (
  "primary": $white,
  "secondary": $white,
  "tertiary": $black,
  "white": $black,
);

// Background
$bg-primary: #fffefe;
$bg-padding-y: 16px;
$bg-padding-x: 24px;

// Navbar
$navbar-height: 65px;

// Sidebar
$sidebar-width: 350px;

// Typography
$font-primary: "Lato", sans-serif;
$base-font-size: 16px;
$font-size-h1: $base-font-size * 2.5;
$font-size-h2: $base-font-size * 1.875;
$font-size-h3: $base-font-size * 1.5625;
$font-size-h4: $base-font-size * 1.5625;
$font-size-h5: $base-font-size * 1.25;
$font-size-p1: $base-font-size * 1.25;
$font-size-p2: $base-font-size;
$font-size-p3: $base-font-size * 0.875;
$font-size-p4: $base-font-size * 0.8125;
$font-size-p5: $base-font-size * 0.8125;
$font-size-p6: $base-font-size * 0.625;

// Elevation
$base-elevation: 0 3px 6px 0 $grey-5;
$sidebar-elevation: 0 3px 6px 0 $grey-2;

// Responsive breakpoints
$breakpoints: (
  "phone": 480px,
  "tablet": 768px,
  "laptop": 1024px,
  "small-screen": 1200px,
);
