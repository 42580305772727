/* Satoshi Font Faces */
@font-face {
  font-family: "Satoshi";
  src: url("../../fonts/Satoshi-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Satoshi";
  src: url("../../fonts/Satoshi-Italic.otf") format("opentype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Satoshi";
  src: url("../../fonts/Satoshi-Light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Satoshi";
  src: url("../../fonts/Satoshi-LightItalic.otf") format("opentype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Satoshi";
  src: url("../../fonts/Satoshi-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Satoshi";
  src: url("../../fonts/Satoshi-MediumItalic.otf") format("opentype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Satoshi";
  src: url("../../fonts/Satoshi-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Satoshi";
  src: url("../../fonts/Satoshi-BoldItalic.otf") format("opentype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Satoshi";
  src: url("../../fonts/Satoshi-Black.otf") format("opentype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Satoshi";
  src: url("../../fonts/Satoshi-BlackItalic.otf") format("opentype");
  font-weight: 900;
  font-style: italic;
}

// Basic Typography Options
h1,
h2,
h3,
h4,
h5,
.p1,
.p2,
.p3,
.p4,
.p5 {
}

h1 {
  font-weight: bold;
  font-size: $font-size-h1;
}

h2 {
  font-weight: bold;
  font-size: $font-size-h2;
}

h3 {
  font-weight: bold;
  font-size: $font-size-h3;
}

h4 {
  font-weight: medium;
  font-size: $font-size-h4;
}

h5 {
  font-weight: bold;
  font-size: $font-size-h5;
}

.p1 {
  font-weight: medium;
  font-size: $font-size-p1 !important;
}

p,
input,
.p2 {
  font-weight: medium;
  font-size: $base-font-size !important;
}

.p3 {
  font-weight: medium;
  font-size: $font-size-p3 !important;
}

.p4 {
  font-weight: medium;
  font-size: $font-size-p4 !important;
}

.p5 {
  font-weight: normal;
  font-size: $font-size-p5 !important;
}

.p6 {
  font-weight: normal;
  font-size: $font-size-p6 !important;
}

.text-left {
  text-align: left !important;
  justify-content: start !important;
}

.text-center {
  text-align: center !important;
  justify-content: start !important;
}

.text-right {
  text-align: right !important;
  justify-content: flex-end !important;
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.text-normal {
  font-weight: normal;
}

.strikethrough {
  text-decoration: line-through;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-wrap {
  white-space: normal !important;
}

@media (max-width: 480px) {
  h1 {
    font-size: 2rem !important;
    letter-spacing: 0.05em !important;
  }

  h2 {
    font-size: 1.3rem !important;
    letter-spacing: 0.05em !important;
  }

  h3,
  h4 {
    font-size: 1rem !important;
    letter-spacing: 0.05em !important;
  }
}
