#support {
  display: flex;
  min-height: 100vh;

  .img-container {
    width: 40%;
    background: url("../../img/login.webp") no-repeat center center/cover;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 1.5rem;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 1;
    }

    * {
      position: relative;
      z-index: 2;
    }
  }

  .container {
    flex-grow: 1;
    padding: 5rem;
  }
}

@media (max-width: breakpoint("laptop")) {
  #support {
    flex-direction: column-reverse !important;

    .img-container {
      width: 100%;
    }

    .container {
      flex-grow: 0;
      min-height: fit-content;
    }
  }
}

@media (max-width: breakpoint("tablet")) {
  #support {
    .container .form-group {
      flex: 0 0 100%;
      padding: 0;
    }
  }
}
